<template>
  <div class="account-wrap">
    <van-list
      :offset="200"
      v-model="loading"
      loading-text=""
      :finished="finished"
      @load="onLoad"
      v-if="list.length"
      class="list-wrap"
    >
      <!-- 流水 -->
      <div class="flowList-wrap">
        <!-- 具体内容 -->
        <div class="flow-box">
          <div v-for="item in list" :key="item.id" class="flow-top-max-wrap">
            <div class="flow-inner">
              <div class="flow-top-wrap dfr main-between cross-center">
                <div class="name fs30 fc333">
                  <span>{{ item.bank }}</span>
                  <span>（卡尾号{{ item.cardNum }}）</span>
                </div>
                <div class="flow-price-box fc333  dfr fs36 fc333">
                  -{{ item.amount }}
                </div>
              </div>
              <div class="time-w fs22 fc999 dfr cross-center main-between">
                <div>{{ item.time }}</div>
                <!-- 0'处理中',1 '已提现', 2'提现失败' -->
                <div class="fs24">
                  <div v-if="item.status == 0">提现处理中</div>
                  <div v-if="item.status == 1">已完成</div>
                  <div v-if="item.status == 2" class="dfr">
                    <div>{{ item.remark }}</div>
                    <div class="fail-err">提现失败</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <empty v-else content="暂无数据"></empty>
  </div>
</template>

<script>
import { timestampFormat } from "../../utils/util";
// import { timestampFormat, homeShare } from "../../utils/util";
import dayjs from "dayjs";
export default {
  props: {},
  data() {
    return {
      isLoading: true,
      list: [],
      page: 1,
      finished: false,
      loading: false,
      pageSize: 15,
      total: 0
    };
  },
  created() {
    this.$title("历史提现");
    // homeShare();
    this.initPage();
  },
  mounted() {},
  methods: {
    async initPage() {
      this.page = 1;
      this.list = await this.initList();
      if (this.total < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
      this.isLoading = false;
    },
    async initList() {
      let list = [];
      const data = {
        page: this.page,
        pageSize: this.pageSize
      };
      const resp = await this.$API
        .post("withdrawalHistoryList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        list = resp.data.list;
        this.total = resp.data.total;

        list.forEach(item => {
          item.time = timestampFormat(dayjs(item.created_at).valueOf() / 1000);
          item.cardNum = item.card_number.slice(item.card_number.length - 4);
        });
      }
      return list;
    },
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const list = await this.initList();

        if (list.length < this.pageSize) {
          this.list = [...this.list, ...list];
          this.loading = false;
          this.finished = true;
        } else {
          this.list = [...this.list, ...list];
          this.loading = false;
          this.finished = false;
        }
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.account-wrap {
  background: #f7f7f7;
  min-height: 100vh;
}
.flowList-wrap {
  padding: 0.2rem 0;
}

.order-type-box .order-item {
  flex: 1;
  padding: 0.26rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-line-box {
  height: 1px;
  transform: scaleY(0.5);
  background: #eee;
  width: 100%;
}

.flow-inner {
  padding-bottom: 0.28rem;
  border-bottom: 0.01rem solid #eee;
}
.order-active {
  width: 0.6rem;
  height: 0.12rem;
  background: linear-gradient(
    90deg,
    rgba(250, 190, 0, 1) 0%,
    rgba(250, 134, 0, 1) 100%
  );
  box-shadow: 0px 0.02rem 0.04rem 0px rgba(250, 190, 0, 0.4);
  border-radius: 0.05rem;
  position: absolute;
  bottom: 0;
  left: 20%;
}
.time-w {
  margin-top: 0.08rem;
}
.flow-top-max-wrap {
  padding: 0 0.36rem 0.28rem;
  background: #fff;
  &:first-child {
    padding-top: 0.28rem;
  }
  &:last-child {
    padding-bottom: 0;
    .flow-inner {
      border: none;
    }
  }
}
.flow-box .time {
  padding: 0.18rem 0 0.28rem;
  border-bottom: 0.01rem solid #eee;
}
.flow-top-max-wrap:last-child .time {
  border: none;
}
.flow-box .name {
  width: 4.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateY(0.05rem);
}
.flow-price {
  display: inline-block;
  transform: translateY(0.03rem);
}
.fail-err {
  color: #ff6c6c;
  margin-left: 0.16rem;
}
</style>
